import * as React from 'react'
import { Hit } from 'react-instantsearch-core'
import { Highlight } from 'react-instantsearch-dom'

import { ITranslationsProvided, withTranslations } from '../../../connectors/translation'
import format from '../../../date/format'
import { parseDateISOString } from '../../../date/parse'
import { ICompositeOpportunity } from '../../../models/algolia/composite'
import { exists } from '../../../util'
import { buildAnalyticsAttributes } from '../../algolia-search/insights'
import { Tooltip } from '../../bootstrap/tooltip'
import { TableViewOptions } from './any-hit'


import WatermarkMediaDefault from '~/images/watermark-media-default.png'
// import ServeIcon from '../../../icons/icon-serve.svg'
import MembershipIcon from '../../../icons/icon-membership.svg'
import { isDate } from '../../../util/date'

type TableViewProps = TableViewOptions &
  ITranslationsProvided & {
    hit: Hit<ICompositeOpportunity>,

    layout?: 'responsive' | 'table' | 'block',

    className?: string,
  }

export class OpportunityHitTableView extends React.PureComponent<TableViewProps> {
  public render() {
    const { className, hit, t } = this.props
    const { Opportunity } = hit
    const thumb = Opportunity.image ? Opportunity.image.url : null

    // fallback to raw data from Rock if we didn't find a match in the Paper Signs space
    const ministry = (hit.ministries && hit.ministries[0]) ||
      hit.Opportunity.ministry
    const campus = (hit.campuses && hit.campuses[0]) || hit.Opportunity.campus

    const expirationDate = Opportunity.expire_date_time ? parseDateISOString(Opportunity.expire_date_time) : null

    const layout = this.props.layout || 'responsive'

    return (
      <div className={`site-search-hit__opportunity site-search-hit__opportunity__${layout} ${className}`}
        {...buildAnalyticsAttributes(hit)}>
        <div className="site-search-hit__opportunity-header">
          <div className="row no-gutters">
            <p className="overline">{
              [
                ministry && ministry.title,
                campus && campus.name,
              ].filter(exists).join(' • ')
            }</p>
          </div>
          <div className="row no-gutters">
            <h4><Highlight attribute="Opportunity.title" hit={hit} /></h4>
            <div className="site-search-hit__opportunity-tags site-search-hit__opportunity-tags__header">
              {this.renderTags({ direction: 'rtl' })}
            </div>
          </div>
        </div>
        <div className="card-deck">
          <div className="card image-only site-search-hit__opportunity-thumb">
            <div className="site-search-hit__opportunity-thumb__image embed-responsive embed-responsive-16by9">
              {thumb &&
                <img className="embed-responsive-item" src={thumb} />}
            </div>
            <div className="site-search-hit__opportunity-tags site-search-hit__opportunity-tags__overlay">
              {this.renderTags({ direction: 'ltr' })}
            </div>
          </div>
          <div className="card site-search-hit__opportunity-text">
            <div className="card-body">
              <div className="card-text">
                <p><Highlight attribute="Opportunity.description" hit={hit} /></p>
                <ul>
                  {Opportunity.background_check_required &&
                    <li>{t('background-check')}</li>}
                  {Opportunity.open_positions && Opportunity.open_positions >= 1 &&
                    <li>{t('open-positions', { count: Opportunity.open_positions })}</li>}
                  {Opportunity.serving_frequency &&
                    <li>{t('serving-frequency', { text: Opportunity.serving_frequency })}</li>}
                  {expirationDate && isDate(Opportunity.expire_date_time) &&
                    <li>
                      {t('expiration', { date: format(expirationDate, 'MMMM d, yyyy') })}
                    </li>}
                </ul>
                <div className="site-search-hit__opportunity-text__buttons">
                  {this.callToAction(Opportunity)}
                  {Opportunity.contact_email &&
                    <a
                      className="btn opportunity-question plausible-event-name=opportunity-contact-form-open-modal-click"
                      href="#"
                      id={this.opportunityCtaId('question')}
                      data-modal-contact={JSON.stringify({
                        email_object_type: 'opportunity',
                        email_object_id: Opportunity.id,
                        email_contact_type: 'question',
                      })}
                      data-toggle="modal"
                      data-target="#opportunityContactModal">
                      <i className="material-icons">contact_support</i>
                      {t('contact-button-text')}
                    </a>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }

  private callToAction(opportunity: Hit<ICompositeOpportunity>['Opportunity']) {
    if (opportunity.call_to_action && opportunity.call_to_action.label) {
      if (/^mailto:/i.test(opportunity.call_to_action.href)) {
        return (
          <a
            className="btn btn-primary opportunity-apply"
            id={this.opportunityCtaId('apply')}
            href="#"
            {...buildAnalyticsAttributes(this.props.hit)}
            data-modal-contact={JSON.stringify({
              email_object_type: 'opportunity',
              email_object_id: opportunity.id,
              email_contact_type: 'apply',
            })}
            data-toggle="modal"
            data-target="#opportunityContactModal"
          >
            {opportunity.call_to_action.label}
          </a>
        )
      } else {
        return (
          <a
            className="btn btn-primary opportunity-apply plausible-event-name=opportunity-apply-click"
            id={this.opportunityCtaId('apply')}
            href={opportunity.call_to_action.href}
          >
            {opportunity.call_to_action.label}
          </a>
        )
      }
    }
  }

  private opportunityCtaId(ctaType: string) {
    const { Opportunity } = this.props.hit
    const id = `${ctaType}-${Opportunity.id}-${Opportunity.title.replace(/\s+/g, '-')}`
    return id.toLowerCase()
  }

  private renderTags(options?: { direction?: 'ltr' | 'rtl' }) {
    const {t, hit} = this.props
    const { Opportunity } = hit

    const { direction } = Object.assign(
      {
        direction: 'ltr',
      },
      options,
    )

    const icons: React.ReactNode[] = [
      Opportunity.membership_required &&
        <Tooltip key="membership-tt" title={t('membership-icon-title')}><img src={MembershipIcon}></img></Tooltip>,
      // Opportunity.fulfills_service_requirement &&
      // <Tooltip key="serve-tt" title={t('serve-icon-title')}><ServeIcon /></Tooltip>,
    ]
    if (direction == 'ltr') {
      return [
        ...icons,
      ]
    } else {
      return [
        ...icons,
      ]
    }
  }
}

const ConnectedOpportunityHitTableView =
  withTranslations(OpportunityHitTableView,
    'hit.opportunity')

export default ConnectedOpportunityHitTableView

export function OpportunityHitLoadingView(props: Partial<TableViewProps>) {
  const {className} = props
  const layout = props.layout || 'responsive'

  return <div className={`site-search-hit__opportunity site-search-hit__opportunity__${layout} loading ${className}`}>
    <div className="site-search-hit__opportunity-header">
      <div className="row no-gutters">
        <p className="overline">&nbsp;</p>
      </div>
      <div className="row no-gutters">
        <h4>&nbsp;</h4>
        <div className="site-search-hit__opportunity-tags site-search-hit__opportunity-tags__header">
          &nbsp;
        </div>
      </div>
    </div>
    <div className="card-deck">
      <div className="card image-only site-search-hit__opportunity-thumb">
        <div className="site-search-hit__opportunity-thumb__image embed-responsive embed-responsive-16by9">
          <img className="embed-responsive-item" src={WatermarkMediaDefault} />
        </div>
        <div className="site-search-hit__opportunity-tags site-search-hit__opportunity-tags__overlay">
          &nbsp;
        </div>
      </div>
      <div className="card site-search-hit__opportunity-text">
        <div className="card-body">
          <div className="card-text">
            <p>&nbsp;</p>
            <ul>
            </ul>
            <div className="site-search-hit__opportunity-text__buttons">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
}
